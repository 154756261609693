export default {
  "settingsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenie Notifikátoru"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornenia Vám budú zasielané podľa Vášho nastavenia nižšie."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uložiť"])},
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše nové nastavenie bolo úspešne uložené."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia nebolo možné uložiť. Skúste obnoviť stránku a vykonať nastavenia znova."])}
  },
  "selectPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať platformu"])},
  "selectVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať variant"])},
  "connectedWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené s účtom"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odpojiť"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo"])},
  "notificationShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslať upozornenie pri doručení"])},
  "forAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pre všetkých"])}
}