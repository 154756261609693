export default {
  "settingsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikátor settings"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikátor will send you notifications about new orders according to your settings below."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new settings have been successfully saved."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try refreshing the page and making the settings again."])}
  },
  "selectPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Platform"])},
  "selectVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Variant"])},
  "connectedWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to Account"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "notificationShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification on Shipping"])},
  "forAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For All"])}
}