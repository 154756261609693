export default {
  "settingsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítés beállítása"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értesítő az alábbi beállításoknak megfelelően fog értesítéseket küldeni Önnek az új rendelésekről."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az új beállításokat sikeresen mentettük el."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beállításokat nem sikerült elmenteni. Kérjük, próbálja meg frissíteni az oldalt, és végezze el újra a beállításokat."])}
  },
  "selectPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform kiválasztása"])},
  "selectVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variáns kiválasztása"])},
  "connectedWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatva a következő fiókkal:"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szétkapcsol"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
  "notificationShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítés küldése a szállításkor"])},
  "forAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindenki számára"])}
}