export default {
  "settingsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení Edudeal"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služba edudeal umožňuje ověřit jednoduše status studenta na Vašem webu či eshopu."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uložit"])},
  "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API klíč k projektu"])},
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše nové nastavení bylo úspěšně uloženo."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení se nepodařilo uložit. Zkuste prosím stránku obnovit a provést nastavení znovu."])}
  },
  "selectPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforma pro příjem"])},
  "selectVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvolte variantu"])},
  "connectedWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojeno s účtem"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odpojit"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
  "notificationShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat upozornění při dopravě"])},
  "forAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro všechny"])}
}