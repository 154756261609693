
<script>
import { useI18n } from 'vue-i18n'
export default {
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data() {
    let data = window.location.search;
    return {
      form: {
        projectApiKey: '',
      },
      saveSuccess: false,
      error: false,
      query: new URLSearchParams(data)
    }
  },
  methods: {
    async loadData() {
      const res = await fetch(process.env.VUE_APP_API_URL + '/shoptet/settings', {
        headers: {
          eshopId: +this.query.get('eshopId'),
          'Shoptet-Authorization': await localStorage.getItem('token')
        }
      });
      const finalRes = await res.json();
      this.form.projectApiKey = finalRes.projectApiKey;
    },
    async saveData() {
      const res = await fetch(process.env.VUE_APP_API_URL + '/shoptet/settings', {
        method: 'POST',
        headers: {
          eshopId: +this.query.get('eshopId'),
          'Shoptet-Authorization': await localStorage.getItem('token'),
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          eshopId: +this.query.get('eshopId'),
          ...this.form
        })
      }).then(res => {
        if (res.status == 201) {
          this.saveSuccess = true;
          this.error = false;
        }
        else {
          this.saveSuccess = false;
          this.error = true;
        }
      })

      const finalRes = await res.json();
      

      return finalRes;
    },
    async login() {
      const res = await fetch(process.env.VUE_APP_API_URL + '/shoptet/login', {
        method: 'POST',
        headers: {
          eshopId: +this.query.get('eshopId'),
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          eshopId: +this.query.get('eshopId'),
          code: this.query.get('code')
        })
      });

      const finalRes = await res.json();
      console.log(finalRes.access_token);
      await localStorage.setItem('token', finalRes.access_token);
      return await localStorage.getItem('token');
      
    }
  },
  async mounted() {
    const that = this;
    this.$i18n.locale = this.query.get('language');
    this.login().then(result => {
      this.loadData();
    });
  }
}

</script>

<style>
.hidden{
  display: none;
}
</style>
<template>
  <main style="margin-top: 80px">

    <div class="content-header">
      <div class="content-buttons">
        <input type="submit" value="" class="fake-submit" />
        <a @click="saveData" href="#" title="Uložit" class="btn btn-md btn-action submit-js" rel="saveAndStay">{{ $t('save') }}</a>
      </div>
    </div>

    <div id="tab-article">
      <h2 class="std-header">{{ $t('settingsHeading') }}</h2>
      <div class="perexBox perexBox--flex">
        <div class="large-11">{{ $t('description') }}
        </div>
        <div class="large-5 perexBox__logo">
            <img style="width: 200px" src="https://edudeal.cz/wp-content/uploads/2023/11/edudeal-logo.png" alt="Edudeal logo" />
        </div>
      </div>
    </div>

    <div class="systemMessage systemMessage--success" v-if="saveSuccess">
      <div class="systemMessage__content">
        <div class="systemMessage__text">{{ $t('notifications.success') }}</div>
      </div>
    </div>
    <div class="systemMessage systemMessage--error" v-if="error">
      <div class="systemMessage__content">
        <div class="systemMessage__text">{{ $t('notifications.error') }}</div>
      </div>
    </div>

    <table class="std-table">
      <tr>
        <td class="text-right">
          <label for="text-long"><span class="required-asterisk">{{ $t('apiKey') }}</span></label>
        </td>
        <td>
          <input v-model="form.projectApiKey" type="text" name="development-field-2-3" id="text-long"
            class="small-12 medium-14 large-8" placeholder="Zadejte svůj API klíč" />
          &nbsp;
          <span class="show-tooltip tooltip-icon"
            title="Zde prosím vložte svůj API klíč projektu, který získate v administračním prostředí edudeal."></span>
        </td>
      </tr>
    </table>


  </main>
</template>

